import { yupResolver } from '@hookform/resolvers/yup';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import AppButton from '../../../components/AppButton';
import { useConfirmationPopup } from '../../../components/AppConfirmationPopup';
import AppDivider from '../../../components/AppDivider';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import {
  DISCOUNT_APPLY_TYPES,
  DISCOUNT_TYPES,
  PERMISSION_TYPES,
} from '../../../constants';
import HashScroll from '../../../hocs/HashScroll';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectDiscounts } from '../../../redux/selectors/discountsSelectors';
import { Colors } from '../../../theme/colors';
import { decimalCalculations } from '../../../utils';
import {
  discountValidationSchema,
  IDiscountForm,
} from '../constants/validation-schemas';
import ViewLayout from '../hocs/ViewLayout';
import {
  getSingleDiscount,
  patchUpdateDiscountsData,
  resetEditingData,
} from '../redux/discountsSlice';
import { UpdateDiscountRequestBodyDTO } from '../types/discounts.types';
import AdvancedFormView from '../views/AdvancedFormView';
import AppliedToFormView from '../views/AppliedToFormView';
import AvailabilityFormView from '../views/AvailabilityFormView';
import DiscountDetailsFormView from '../views/DiscountDetailsFormView';

const EditDiscountsPage = () => {
  const dispatch = useAppDispatch();
  const { openConfirmationPopup } = useConfirmationPopup();
  const { editingData, loadingSingleDiscount } =
    useAppSelector(selectDiscounts);
  const { user } = useAppSelector(selectAuth);
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const form = useForm<IDiscountForm>({
    resolver: yupResolver(discountValidationSchema),
    defaultValues: {
      discountType: '' as DISCOUNT_TYPES,
      isAvailableAlways: true,
      permissionLevel: '' as PERMISSION_TYPES.MANAGER,
      discountReasons: [],
      combineDiscounts: true,
    },
  });

  const getSingleDiscountCallback = useCallback(
    async (pathParamId: string) => {
      const res = await dispatch(getSingleDiscount(pathParamId));
      if (res.meta.requestStatus === 'rejected') {
        navigate('/discounts');
      }
    },
    [dispatch, navigate],
  );

  useLayoutEffect(() => {
    if (id) {
      getSingleDiscountCallback(id);
    }
  }, [id, getSingleDiscountCallback]);

  useEffect(() => {
    if (editingData) {
      form.setValue('discountName', editingData.name);
      form.setValue('discountType', editingData.type);
      form.setValue(
        'discountValue',
        decimalCalculations(editingData.value).toDecimal().toString(),
      );
      form.setValue('discountDescription', editingData.description);
      const removeDiscount =
        (editingData.excludedCategories?.length ?? 0) > 0 ||
        (editingData.excludedDishes?.length ?? 0) > 0;

      form.setValue('applyType', editingData.applyType);
      form.setValue('removeDiscount', removeDiscount);
      if (
        editingData.applyType === DISCOUNT_APPLY_TYPES.FULL_ORDER &&
        removeDiscount
      ) {
        form.setValue('excludedCategories', editingData.excludedCategories);
        form.setValue('excludedDishes', editingData.excludedDishes);
      }
      if (editingData.applyType === DISCOUNT_APPLY_TYPES.SPECIFIC_ITEM) {
        form.setValue('includedCategories', editingData.includedCategories);
        form.setValue('includedDishes', editingData.includedDishes);
      }
      form.setValue('isAvailableAlways', editingData.isAvailableAlways);
      form.setValue('permissionLevel', editingData.permissionLevel);
      form.setValue('discountReasons', editingData.discountReasons);
      form.setValue('combineDiscounts', editingData.combineDiscounts);
    }
  }, [editingData, form]);

  const onSubmit = form.handleSubmit(async (data) => {
    const payload: UpdateDiscountRequestBodyDTO = {
      name: data.discountName,
      value: decimalCalculations(data.discountValue).toCents().toNumber(),
      type: data.discountType,
      description: data.discountDescription,
      applyType: data.applyType,
      excludedDishes:
        data.applyType === DISCOUNT_APPLY_TYPES.FULL_ORDER &&
        data.removeDiscount
          ? data.excludedDishes
          : [],
      excludedCategories:
        data.applyType === DISCOUNT_APPLY_TYPES.FULL_ORDER &&
        data.removeDiscount
          ? data.excludedCategories
          : [],
      includedDishes:
        data.applyType === DISCOUNT_APPLY_TYPES.SPECIFIC_ITEM
          ? data.includedDishes
          : [],
      includedCategories:
        data.applyType === DISCOUNT_APPLY_TYPES.SPECIFIC_ITEM
          ? data.includedCategories
          : [],
      isAvailableAlways: data.isAvailableAlways,
      permissionLevel: data.permissionLevel,
      discountReasons: data.discountReasons,
      combineDiscounts: data.combineDiscounts,
      updatedBy: Number(user?.empId),
    };

    const response = await dispatch(
      patchUpdateDiscountsData({ _id: String(id), data: payload }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      openSnackbar('Discount updated successfully!', SNACKBARTYPE.SUCCESS);
      navigate('/discounts');
    }
    dispatch(resetEditingData());
  });

  const handlePreviousClick = () => {
    openConfirmationPopup({
      heading: `Discard Discount Changes?`,
      content: 'You will loose all the changes made to this discount.',
      confirmButtonTitle: 'Discard changes',
      cancelButtonTitle: 'Keep editing',

      onConfirm: () => {
        dispatch(resetEditingData());
        navigate('/discounts/all-discounts');
      },
    });
  };

  return (
    <Box
      sx={{
        position: 'relative',
        opacity: loadingSingleDiscount ? 0.5 : 1,
      }}>
      {loadingSingleDiscount && (
        <Backdrop
          open={true}
          sx={{
            position: 'absolute',
            zIndex: 999,
            backgroundColor: 'transparent',
          }}>
          <CircularProgress size="25px" thickness={5} />
        </Backdrop>
      )}
      <ViewLayout>
        <FormProvider {...form}>
          <form onSubmit={onSubmit}>
            <HashScroll hashInput="#discount-details">
              <DiscountDetailsFormView disabled={true} />
            </HashScroll>
            <AppDivider
              style={{
                borderColor: Colors.greyBorderLogin,
                borderTopStyle: 'solid',
                mt: 0.5,
                mb: 1.5,
              }}
            />
            <HashScroll hashInput="#applied-to">
              <AppliedToFormView disabled={true} />
            </HashScroll>
            <AppDivider
              style={{
                borderColor: Colors.greyBorderLogin,
                borderTopStyle: 'solid',
                mt: 0.5,
                mb: 1.5,
              }}
            />
            <HashScroll hashInput="#availability">
              <AvailabilityFormView />
            </HashScroll>
            <AppDivider
              style={{
                borderColor: Colors.greyBorderLogin,
                borderTopStyle: 'solid',
                mt: 0.5,
                mb: 1.5,
              }}
            />
            <HashScroll hashInput="#advanced">
              <AdvancedFormView />
            </HashScroll>
            <AppDivider
              style={{
                borderColor: Colors.greyBorderLogin,
                borderTopStyle: 'solid',
                mt: 0.5,
                mb: 1,
              }}
            />
            <Box
              sx={{
                display: 'flex',
                mt: 2,
                gap: 2,
                justifyContent: 'flex-end',
              }}>
              <AppButton
                size="large"
                title="Cancel"
                color="secondary"
                variant="contained"
                onClick={handlePreviousClick}
                disabled={form.formState.isSubmitting}
              />
              <AppButton
                size="large"
                title="Save"
                variant="contained"
                type="submit"
                disabled={form.formState.isSubmitting}
                loading={form.formState.isSubmitting}
              />
            </Box>
          </form>
        </FormProvider>
      </ViewLayout>
    </Box>
  );
};
export default EditDiscountsPage;
